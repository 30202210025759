@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@350;600&display=swap');

@font-face {
  font-family: 'DINPro';
  src: url('DINPro/DINPro.otf') format('opentype'),
       url('DINPro/DINPro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  height: 100%;
  font-family: 'Nunito Sans', sans-serif; /* Use Nunito Sans font */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
