
/* Customize the label (the container) */
.custom_check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: rgb(255, 255, 255);
  border: solid black;
  border-width: 1px 1px 1px 1px
}

/* On mouse-over, add a grey background color */
.custom_check:hover input ~ .checkmark {
  background-color: rgb(198, 198, 198);
}

/* When the checkbox is checked, add a blue background */
.custom_check input:checked ~ .checkmark {
  /* background-color: #95279E; */
  background-color: #000000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom_check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom_check .checkmark:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
