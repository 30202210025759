@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overscroll-behavior: none
}

/* include border and padding in element width and height */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  flex-direction: column;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

:root {
  /* --callToAction: #95279E; */
  --callToAction: #000000;
  --chartMain: #95279E;
  --chartSecond: #4A144F;
  --chartThird: #67757e;
  --chartFourth: #7b677e;
  --fullwidth: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(103, 103, 103);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* hide scrollbar if needed */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0px auto 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 236px;
  font-weight: 200;
  margin: 0px;
  color: "white";
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 20%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  background: black;
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
}

.notfound a {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 13px 23px;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
  font-size: 18px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: white;
  background: var(--bg-teal-700);
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size: 148px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 148px;
    margin: 0px auto 10px;
  }

  .notfound .notfound-404 h1 {
    font-size: 86px;
  }

  .notfound .notfound-404 h2 {
    font-size: 16px;
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}

  /* Custom scrollbar styles */
  .overflow-x-auto::-webkit-scrollbar {
    width: 2px; /* Width of the scrollbar */
    height: 6px;
}

.overflow-x-auto::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 8px; /* Round corners for the track */
}

.overflow-x-auto::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 8px; /* Round corners for the thumb */
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the thumb when hovered */
}
.bg-lime-400 {
  background-color: #84cc16;  /* Lime 400 */
}

.bg-lime-800 {
  background-color: #3b821d;  /* Lime 800 */
}

.bg-green-400 {
  background-color: #34d399;  /* Green 400 */
}

.bg-blue-500 {
  background-color: #3b82f6;  /* Blue 500 */
}

.bg-violet-500 {
  background-color: #8b5cf6;  /* Violet 500 */
}

.bg-rose-500 {
  background-color: #f43f5e;  /* Rose 500 */
}

.bg-cyan-400 {
  background-color: #22d3ee;  /* Cyan 400 */
}

.bg-teal-400 {
  background-color: #2dd4bf;  /* Teal 400 */
}

.bg-amber-400 {
  background-color: #fbbf24;  /* Amber 400 */
}



/* Base event container */
.fc-event,
.fc-event * {
  box-shadow: none !important;
  border: none;
  outline: none !important
}
.fc-v-event {
  background-color: transparent;
  border: none !important;
  padding: 0;
  margin: 0 !important;
}

.fc-event {
  background-color: transparent;
  border: none !important;
  padding: 0 !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 !important;
}

.fc-event-title {
  font-size: 13px !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-event-main {
  background-color: transparent !important;
  border: none !important;
  border-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important; 
}

.fc-timegrid-event-harness {
  background-color: transparent;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-color: transparent;
}