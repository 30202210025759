
/* Add these classes to your CSS */
.scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(156, 163, 175, 0.5) transparent; /* For Firefox */
    border: none; /* Remove any borders */
    outline: none; /* Remove any outlines */
  }
  
  .scrollbar::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Track background */
    border: none; /* Remove any borders */
    box-shadow: none; /* Remove any shadows */
    width: 0; /* Minimize width */
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.5); /* Thumb color */
    border-radius: 20px; /* Roundness */
  }
  
  /* Hide scrollbar for mobile */
  .mobile-no-scrollbar {
    /* For Safari, Chrome, and newer Edge */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
  }
  
  /* For WebKit browsers (Chrome, Safari) */
  @media (max-width: 768px) {
    .mobile-no-scrollbar::-webkit-scrollbar {
      display: none;
    }
  }
  
  /* At larger screen sizes, restore scrollbar */
  @media (min-width: 769px) {
    .mobile-no-scrollbar {
      scrollbar-width: thin; /* For Firefox */
      -ms-overflow-style: auto; /* For IE and Edge */
    }
    
    .mobile-no-scrollbar::-webkit-scrollbar {
      display: block;
      width: 6px; /* Control width */
    }
    
    /* These additional selectors help eliminate any unwanted lines */
    .mobile-no-scrollbar::-webkit-scrollbar-button {
      display: none; /* Remove button */
    }
    
    .mobile-no-scrollbar::-webkit-scrollbar-corner {
      background: transparent; /* Corner transparent */
    }
  }