.gm-style .gm-style-iw-c {
    padding-right: 0px !important;
     padding-bottom: 0px !important;
     max-width: 500px !important;
     max-height: 326px !important;
     min-width: 200px !important;
     position: absolute;
     box-sizing: border-box;
     overflow: hidden;
     top: 0;
     left: 0;
     transform: translate(-50%,-100%);
     border-radius: 20px;
     box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
 }
