.custom-input {
    width: 100%;
    text-align: left;
    border: none;
    border-bottom: 1px solid #4b5563; /* Normal state: Gray-600 */
    outline: none;
    transition: border-bottom 0.2s ease-in-out;
}

.custom-input:focus {
    border-bottom: 2px solid #374151; /* Focus state: Gray-800 */
    outline: none;
}