Ol.progtrckr {
    margin: 0;
    padding-bottom: 2.2rem;
    list-style-type: none;
  }
Ol.progtrckr li {
display: inline-block;
text-align: center;
line-height: 4.5rem;
padding: 0 0.7rem;
cursor: pointer;
}
Ol.progtrckr li span {
padding: 0 1.5rem;
}
@media (max-width: 650px) {
.progtrckr li span {
    display: none;
}
}
.progtrckr em {
display: none;
font-weight: 700;
padding-left: 1rem;
}
@media (max-width: 650px) {
.progtrckr em {
    display: inline;
}
}

Ol.progtrckr li.progtrckr-todo {
cOlor: silver;
border-bottom: 4px sOlid silver;
}
Ol.progtrckr li.progtrckr-doing {
cOlor: black;
border-bottom: 4px sOlid #7e7067;
}
Ol.progtrckr li.progtrckr-done {
cOlor: black;
border-bottom: 4px sOlid #7e7067;
}
Ol.progtrckr li:after {
content: "\00a0\00a0";
}
Ol.progtrckr li:before {
position: relative;
bottom: -3.7rem;
float: left;
left: 50%;
}
Ol.progtrckr li.progtrckr-todo:before {
content: "\039F";
cOlor: silver;
background-cOlor: white;
width: 1.2em;
line-height: 1.4em;
}
Ol.progtrckr li.progtrckr-todo:hover:before {
cOlor: #7e7067;
}

Ol.progtrckr li.progtrckr-doing:before {
content: "\2022";
cOlor: white;
background-cOlor: #7e7067;
width: 1.2em;
line-height: 1.2em;
border-radius: 1.2em;
}
Ol.progtrckr li.progtrckr-doing:hover:before {
cOlor: #7e7067;
}

Ol.progtrckr li.progtrckr-done:before {
content: "\2713";
cOlor: white;
background-cOlor: #7e7067;
width: 1.2em;
line-height: 1.2em;
border-radius: 1.2em;
}
Ol.progtrckr li.progtrckr-done:hover:before {
cOlor: #7e7067;
}
